import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactForm from "components/ContactForm"

import {
  Content,
  Video,
  Mission,
  GreenArea,
  Baner,
} from "page_components/developer"

const Support = ({ location }) => {
  const title = "Траст Інвестмент"

  return (
    <Layout
      location={location}
      seo={{
        title: "Девелопер",
        description:
          "Численні проекти, задоволені клієнти, житлові та комерційні проекти по всій Польщі. Познайомтеся з АТ Траст Інвестмент - перевірений розробник.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Video />
      <Mission />
      <GreenArea />
      <Baner />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export default Support
