import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const GreenArea = () => {
  const { t } = useLocale()

  return (
    <section className="developer-green-area">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2>{t("Zieleń jest najważniejsza")}</h2>
            <p>
              {t(
                "Naszym zadaniem jest tworzenie osiedli mieszkaniowych o wysokim standardzie oraz pozbawionych barier obiektów handlowo-usługowych. Stawiamy na jak najbardziej ekologiczne rozwiązania i walczymy o zachowanie możliwie największych terenów zieleni w obrębie inwestycji."
              )}
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={
                require("assets/images/developer/developer-green-area-image.png")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GreenArea
