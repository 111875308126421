/* eslint-disable jsx-a11y/control-has-associated-label */
import "./styles.scss"

import React, { useState } from "react"
import ReactPlayer from "react-player"

import IcoPlay from "assets/icons/play.svg"

const Video = () => {
  const [videoPlay, setVideoPlay] = useState(false)

  return (
    <section className="developer-video">
      <div className="container-fluid">
        <div className="developer-video__wrapper">
          <ReactPlayer
            url="https://player.vimeo.com/video/389933417/"
            width="100%"
            height="100%"
            playing={videoPlay}
            controls={true}
            muted={false}
          />
          {!videoPlay && (
            <>
              <div className="developer-video__poster" />
              <button
                className="developer-video__play"
                onClick={() => setVideoPlay(true)}
              >
                <img src={IcoPlay} alt="" />
              </button>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default Video
