import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

const Baner = () => {
  const { t, lang } = useLocale()

  return (
    <section className="deweloper-baner">
      <div className="container-fluid">
        <h2
          dangerouslySetInnerHTML={{
            __html: t("Współpraca buduje, <br /> porozmawiajmy."),
          }}
        ></h2>
        <div className="row justify-content-end">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <Link to={t("/mieszkania/")} className="button button--circle">
              {t("KUP MIESZKANIE")}
            </Link>
          </div>
          <div className="col-md-4 col-lg-3 col-xl-2">
            <Link to={t("/kupimy-grunty/")} className="button button--circle">
              {t("SPRZEDAJ GRUNT")}
            </Link>
          </div>
          {lang === "pl" && (
            <div className="col-md-4 col-lg-3 col-xl-2">
              <Link to={t("/kariera/")} className="button button--circle">
                {t("DOŁĄCZ DO NAS")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Baner
