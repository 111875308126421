import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Mission = () => {
  const { t } = useLocale()
  return (
    <section className="developer-mission">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <h2>{t("Nasza misja")}</h2>
          </div>
          <div className="col-md-8">
            <h3>
              {t(
                "Myślą przewodnią w Trust Investment jest człowiek, dlatego założenia biznesowe traktujemy jak misję. Uważamy, że realizacja wyznaczonego celu wymaga profesjonalnego oraz przejrzystego planu, który krok po kroku prowadzi do sukcesu."
              )}
            </h3>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-md-4">
            <div
              className="developer-mission__image"
              style={{
                backgroundImage: `url('${
                  require("assets/images/developer/developer-mission-image.jpg")
                    .default
                }')`,
              }}
            />
          </div>
          <div className="col-md-7 offset-md-1">
            <div className="developer-mission__item">
              <h4>
                <span>01/</span> {t("Zakup działek")}
              </h4>
              <p>
                {t(
                  "Dział ekspansji koncentruje się na najlepszych lokalizacjach, które odpowiadają oczekiwaniom naszych klientów."
                )}
              </p>
            </div>
            <div className="developer-mission__item">
              <h4>
                <span>02/</span> {t("Pozwolenia i projekt architektoniczny")}
              </h4>
              <p>
                {t(
                  "Dział przygotowania inwestycji kładzie nacisk na jakość projektu oraz dostosowuje go do najwyższych standardów."
                )}
              </p>
            </div>
            <div className="developer-mission__item">
              <h4>
                <span>03/</span> {t("Budowa")}
              </h4>
              <p>
                {t(
                  "Dzięki naszym sprawdzonym ekipom budowlanym ten kluczowy proces przebiega sprawnie i według założonego harmonogramu."
                )}
              </p>
            </div>

            <div className="developer-mission__item">
              <h4>
                <span>04/</span> {t("Wynajem lub sprzedaż")}
              </h4>
              <p>
                {t(
                  "Specjaliści ds. sprzedaży i wynajmu nieruchomości komercjalizują oraz sprzedają powierzchnię obiektów."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
