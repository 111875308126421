import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()

  return (
    <section className="developer-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <h3>
              {t(
                "Naszym głównym celem jest podnoszenie standardu życia ludzi w całej Polsce. Nie tylko w największych aglomeracjach, ale również w mniejszych miastach."
              )}
            </h3>
            <p>
              {t(
                "W Trust Investment realizujemy projekty w sektorze nieruchomości mieszkaniowych oraz komercyjnych na terenie całej Polski. Inwestycje realizujemy kompleksowo, począwszy od zakupu gruntu, poprzez komercjalizację, budowę, sprzedaż, aż po przekazanie kluczy właścicielowi lub najemcy. Naszym zdaniem specjalizacja rodzi profesjonalizm, a profesjonalizm budzi chęć bycia lepszym. Wierni tej idei skupiamy się na dwóch gałęziach obszernego rynku nieruchomości - inwestycjach mieszkaniowych oraz komercyjnych."
              )}
            </p>
            <h4 className="developer-content__sub">
              {t("Inwestycje zrealizowane, w trakcie budowy lub w przygotowaniu")}
            </h4>
            <span className="developer-content__area">
              400 000 m<sup>2</sup>
            </span>
            <p>
              {t(
                "Jako zespół Trust Investment każdego roku konsekwentnie wyznaczamy sobie coraz wyższe cele oraz z sukcesem je realizujemy, co owocuje współpracą z partnerami biznesowymi o zasięgu ogólnopolskim. Jesteśmy gwarantem jakości, stabilizacji oraz bezpieczeństwa na każdym etapie współpracy."
              )}
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={
                require("assets/images/developer/developer-content-image.jpg")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
